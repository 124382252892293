import React from 'react'
import SEO from '../components/seo'
import Header from '../components/Header/Header'
import TDSPolicy from '../components/TDSPolicy/TDSPolicy'
import FooterDesk from '../components/Footer/FooterDesk'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'

const PrivacyPolicy: React.FC = () => (
  <>
    <Header />
    <main className="main">
      <SEO
        title="TDS Policy: Read About the TDS Policy at Mega"
        description="Read about the tds policy at Mega. Get to know about data collection, usage, and protection by Mega. Click to know more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />
      <TDSPolicy />
    </main>

    <FooterDesk />
  </>
)

export default PrivacyPolicy
