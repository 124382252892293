import React from 'react'
import Container from 'react-bootstrap/esm/Container'

import './TDSPolicy.scss'

const TDSPolicy: React.FC = () => {
  return (
    <div id="tds-policy">
      <Container>
        <h1>TDS Policy</h1>
      </Container>
      <section>
        <Container>
          <p>
            Net winnings on Mega are subject to the Tax Deducted at Source
            (TDS), which is submitted to the Indian tax authorities under
            Section 194B of the Income Tax Act. The revised TDS Policy at Mega
            has been recommended by senior tax consultants, and proves to be
            extremely favourable for our users
          </p>
        </Container>
      </section>
      <section className="section-contd">
        <Container>
          <h2 className="underlined">Our new TDS Policy</h2>
          <p>Effective as on 1 Aug 2021</p>
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              TDS to be deducted on each hand where Net Winnings (Winnings - Bet
              Amount) crosses Rs10,000
            </li>
            <li>
              31.2% TDS will be applicable when Total amount to be gained
              crosses Rs. 10,000 from one hand
            </li>
            <li>TDS will NOT be deducted on a player’s Bet amount.</li>
          </ol>
          <p>Before 1 Aug 2021:</p>
          <p>
            TDS of 31.2% was deducted if the total winnings in 1 sitting crossed
            Rs 10,000, as per government rules. The amount would be credited to
            your wallet only after the necessary tax deductions.
          </p>
        </Container>
      </section>
      <section className="section-contd">
        <Container>
          <h2 className="underlined">Benefits of new policy</h2>
          <ul>
            <li>
              Bet Amount is not considered, hence TDS is only applicable on NET
              WINNINGS
            </li>
            <li>
              Players don’t need to worry about deduction when stack crosses Rs.
              10,000.
            </li>
            <li>
              TDS is only applicable when the total pool is larger than 10,000
              on any HAND, or DEAL (i.e. total money player has to gain in that
              hand is {'>'}
              Rs.10,000 after subtracting the bet amount)
            </li>
          </ul>
        </Container>
      </section>
      <section className="white-bg">
        <Container>
          <p>
            Example: A player Ramesh plays a couple of hands of Rummy/Poker in a
            single table, and below is the distinction between TDS calculation
            as per previous policy & current policy.
          </p>
        </Container>
      </section>
      <section>
        <Container>
          <p>Previous TDS Policy</p>
          <table className="winnings-table">
            <tr>
              <th>Hands</th>
              <th>Winning/Loss</th>
            </tr>
            <tr>
              <td>Hand 1</td>
              <td>1800</td>
            </tr>
            <tr>
              <td>Hand 2</td>
              <td>600</td>
            </tr>
            <tr>
              <td>Hand 3</td>
              <td>-360</td>
            </tr>
            <tr>
              <td>Hand 4</td>
              <td>1950</td>
            </tr>
            <tr>
              <td>Hand 5</td>
              <td>-720</td>
            </tr>
            <tr>
              <td>Hand 6</td>
              <td>1800</td>
            </tr>
            <tr>
              <td>Hand 7</td>
              <td>2280</td>
            </tr>
            <tr>
              <td>Hand 8</td>
              <td>1350</td>
            </tr>
            <tr>
              <td>Hand 9</td>
              <td>1080</td>
            </tr>
            <tr>
              <td>Hand 10</td>
              <td>420</td>
            </tr>
            <tr>
              <td>Net Winning</td>
              <td>10200</td>
            </tr>
            <tr>
              <td>TDS(31.2%)</td>
              <td>3182.4</td>
            </tr>
          </table>
        </Container>
      </section>
      <section className="white-bg">
        <Container>
          <p>Current TDS Policy</p>
          <table className="winnings-table">
            <tr>
              <th>Hands</th>
              <th>Winning/Loss</th>
              <th>TDS(31.2%)</th>
            </tr>
            <tr>
              <td>Hand 1</td>
              <td>1800</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hand 2</td>
              <td>600</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hand 3</td>
              <td>-360</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hand 4</td>
              <td>1950</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hand 5</td>
              <td>-720</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hand 6</td>
              <td>1800</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hand 7</td>
              <td>2280</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hand 8</td>
              <td>1350</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hand 9</td>
              <td>1080</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Hand 10</td>
              <td>420</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Net Winning</td>
              <td>10200</td>
              <td>0</td>
            </tr>
          </table>
          <h2>Poker: When can you expect TDS deductions?</h2>
          <p>
            Suppose, A Player named Ramesh is playing Texas Hold’em Poker in a{' '}
            <b>6 players table at BB200</b> table and bet amounts for that
            particular hand is distributed as below,
          </p>
          <ul>
            <li>
              <b>Ramesh</b> - Bet amount Rs. 2000
            </li>
            <li>
              <b>Player 1</b> - Bet amount Rs. 2000
            </li>
            <li>
              <b>Player 2</b> - Bet amount Rs. 2000
            </li>
            <li>
              <b>Player 3</b> - Bet amount Rs. 2000
            </li>
            <li>
              <b>Player 4</b> - Bet amount Rs. 2000
            </li>
            <li>
              <b>Player 5</b> - Bet amount Rs. 2000
            </li>
          </ul>
          <p>
            Ramesh won the match and gained{' '}
            <b>Winnings of Rs. 12,000 and Net Winnings of Rs. 10,000</b> in this
            hand. In this scenario, as per the current TDS policy 31.2% TDS will
            be deducted on Rs. <b>10,000</b>
          </p>
          <h2>Rummy: When can you expect TDS deductions?</h2>
          <p>
            Suppose, the same player Ramesh is playing Rummy in a{' '}
            <b>6 players table at PR30</b> table and his gameplay follows like,
          </p>
          <ul>
            <li>
              <b>Player 1</b> - Losses by 80 points
            </li>
            <li>
              <b>Player 2</b> - Losses by 80 points
            </li>
            <li>
              <b>Player 3</b> - Losses by 80 points
            </li>
            <li>
              <b>Player 4</b> - Losses by 80 points
            </li>
            <li>
              <b>Player 5</b> - Losses by 80 points
            </li>
          </ul>
          <p>
            Ramesh won the match by 400 points with{' '}
            <b>net winnings of 12,000 rupees.</b> In this scenario, as per the
            current TDS policy 31.2% TDS will be deducted (i.e. 3,744 rupees)
            and the remaining balance will be credited to the stack.
          </p>
          <p>
            In a nutshell, A player with total points won upto 333 points in a
            single match at PR tables upto PR30, will not be eligible for any
            TDS deduction in Rummy.
          </p>
        </Container>
      </section>
      <section>
        <Container>
          <h2>When is the TDS certificate issued:</h2>
          <p>
            Quarterly (4 times in a financial year -every 3months). Once, TDS
            certificate is generated it is also mailed to your registered Email
            ID with Mega. At the end of every quarter, it takes 15 to 45 days
            for TDS certificate to be generated
          </p>
          <p>Quarter End - Filing max limit is 30days</p>
          <p>Certificate Generation - 15days post filing is completed</p>
        </Container>
      </section>
      <section className="section-contd">
        <Container>
          <h2>Where can I Check for TDS balance for An Assessment Year:</h2>
          <p>
            <b>Step 1:</b> Login to ‘e-Filing’ Portal{' '}
            <a href="https://www.incometaxindiaefiling.gov.in">
              www.incometaxindiaefiling.gov.in
            </a>
          </p>
          <p>
            <b>Step 2:</b> Go to the My ‘Account‘ menu, click ‘View Form 26AS
            (Tax Credit)‘ link.
          </p>
          <p>
            <b>Step 3:</b> Read the disclaimer, click ‘Confirm‘ and the user
            will be redirected to TDS-CPC Portal
          </p>
          <p>
            <b>Step 4:</b> In the TDS-CPC Portal, Agree the acceptance of usage.
            Click ‘Proceed‘.
          </p>
          <p>
            <b>Step 5:</b> Click ‘View Tax Credit (Form 26AS)’
          </p>
          <p>
            <b>Step 6:</b> Select the ‘Assessment Year’ and ‘View type’ (HTML,
            Text or PDF)
          </p>
          <p>
            <b>Step 7:</b> Click ‘View / Download’
          </p>
        </Container>
      </section>
      <section className="section-contd">
        <Container>
          <p>
            At the end of every Financial Year, you can file for TDS returns.
            The claim procedure varies for each user according to profession and
            income. Hence, We would request you to reach out to a Tax Consultant
            while filing ITR.
          </p>
        </Container>
      </section>
    </div>
  )
}

export default TDSPolicy
